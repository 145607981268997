.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  /* background-color: red; */
}

.spinner-line {
  position: absolute;
  width: 3px;
  height: 7px;
  border-radius: 20px;
  background-color: rgb(80, 80, 80);
  transform-origin: center;
  animation: ios-loader-animation 0.7s ease-in-out infinite;
}

.spinner-line:nth-child(1) {
  transform: rotate(0deg) translate(10px) rotate(-90deg);
  animation-delay: 0.1s;
}

.spinner-line:nth-child(2) {
  transform: rotate(45deg) translate(10px) rotate(-90deg);
  animation-delay: 0.2s;
}

.spinner-line:nth-child(3) {
  transform: rotate(90deg) translate(10px) rotate(-90deg);
  animation-delay: 0.3s;
}

.spinner-line:nth-child(4) {
  transform: rotate(135deg) translate(10px) rotate(-90deg);
  animation-delay: 0.4s;
}

.spinner-line:nth-child(5) {
  transform: rotate(180deg) translate(10px) rotate(-90deg);
  animation-delay: 0.5s;
}

.spinner-line:nth-child(6) {
  transform: rotate(225deg) translate(10px) rotate(-90deg);
  animation-delay: 0.6s;
}

.spinner-line:nth-child(7) {
  transform: rotate(270deg) translate(10px) rotate(-90deg);
  animation-delay: 0.7s;
}

.spinner-line:nth-child(8) {
  transform: rotate(315deg) translate(10px) rotate(-90deg);
  animation-delay: 0.8s;
}

@keyframes ios-loader-animation {
  from {
    background-color: rgba(56, 56, 56, 0.5);
  }
  to {
    background-color: rgb(76, 76, 76);
  }
}
