@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&display=swap");

body,
html {
  overflow-x: hidden;
}

* {
  @apply transition-colors duration-[50ms] ease-in-out;
  @apply font-lexend-deca;
}

.text-shadow-white {
  text-shadow: 0px 0px 25px #fffffff0;
}
.text-shadow-black {
  text-shadow: 0px 0px 5px #7c7c7cf0;
}
